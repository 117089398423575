<template>
  <section class="m-merchant-application m-scroll">
    <!-- 头部导航 -->
    <m-header :title="title"></m-header>

    <template v-if="tab == 1">
      <div class="form">
            <p class="label">Company Name</p>
            <input v-model="form.corporate_name"  type="text" class="reset-input" placeholder="Please enter company name">

            <p class="label">Business category</p>
            <div class="input-select" @click="itemPicker = true">
              <input v-model="form.category" type="text" readonly placeholder="Please enter business category">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <!-- <input v-model="form.item" type="text" class="bottom-input" placeholder="Enter your business category"> -->
          </div>

          <div class="form">

            <p class="label">Contact Email</p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="E.g：stehr.myah@santa.us">

            <p class="label">Contact person</p>
            <div class="input-box">
              <input v-model="form.surname" type="text" class="reset-input" placeholder="First name">
              <input v-model="form.name"  type="text" class="reset-input" placeholder="Last name">
            </div>

            <p class="label">Contact title</p>
            <input v-model="form.position" type="text" class="reset-input" placeholder="Contact title">

            <p class="label">Contact number</p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.area_code == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.area_code}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>
          </div>

          <div class="form">

            <p class="label">Business address</p>
            <div class="input-select" style="margin-bottom:10px" @click="showPicker = true">
              <input v-model="cityText" type="text" readonly placeholder="Select province/territory">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.addr" type="text" class="reset-input" placeholder="Detailed address and zip code">
  

            <p class="label" style="margin-bottom:10px">Business license</p>
             <m-upload :img.sync="form.license_pic" file-name="MerchantApply" text="License"></m-upload>
          </div>

          <div class="footer">
            <van-button @click="NextBussiness" :disabled="disabled" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
            <van-button :disabled="disabled" round block plain type="info" native-type="submit" color="#3284FA" style="margin-top:16px"
            @click="viewContract">
              Preview contract
            </van-button>
          </div>
    </template>

    <template v-else>
       <div class="form">
            <p class="label">Shop Name</p>
            <input v-model="form.corporate_name"  type="text" class="reset-input" placeholder="Please enter shop`s name">

            <p class="label">Business category</p>
            <div class="input-select" @click="itemPicker = true">
              <input v-model="form.category" type="text" readonly placeholder="Please enter business category">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.item" type="text" class="bottom-input" placeholder="Enter your business category">
          </div>

          <div class="form">

            <p class="label">Contact Email</p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="E.g：stehr.myah@santa.us">

            <p class="label">Contact person</p>
            <div class="input-box">
              <input v-model="form.surname" type="text" class="reset-input" placeholder="First name">
              <input v-model="form.name"  type="text" class="reset-input" placeholder="Last name">
            </div>

            <p class="label">Contact number</p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.area_code == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.area_code}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>
          </div>

          <div class="form">

            <p class="label">Contract address</p>
            <div class="input-select" style="margin-bottom:10px" @click="showPicker = true">
              <input v-model="cityText" type="text" readonly placeholder="Select province/territory">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.addr" type="text" class="reset-input" placeholder="Detailed address and zip code">
            

            <p class="label" style="margin-bottom:10px">Contact person driving license/passport</p>
            <div class="card-upload">
              <m-upload :img.sync="form.id_card_pic_one" file-name="MerchantApply" text="Front"></m-upload>
              <m-upload :img.sync="form.id_card_pic_two" file-name="MerchantApply" text="Back"></m-upload>
            </div>
          </div>

          <div class="footer">
            <van-button @click="NextPreson" :disabled="disabled" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
            <van-button round block plain type="info" native-type="submit" color="#3284FA" style="margin-top:16px"
            @click="viewContract">
              Preview contract
            </van-button>
          </div>
    </template>

    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="Cancel" close-on-click-action @cancel="telPicker = false" @select="handlearea_code" />

    <!-- 选择经营类目 -->
    <van-popup v-model="itemPicker" position="bottom">
      <van-picker show-toolbar title="Business category" :columns="items" @confirm="handleItem" value-key="title_en" cancel-button-text="Cancel" confirm-button-text="Done" @cancel="itemPicker = false" />
    </van-popup>

    <!-- 选择地址 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar title="Select Address" :columns="columns" @confirm="handleCity" value-key="ename" cancel-button-text="Cancel" confirm-button-text="Done" @cancel="showPicker = false" />
    </van-popup>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
import { category, city, shop_apply } from '@/api/zh/mine.js'
import MUpload from '@/components/zh/m-upload.vue'

export default {
  name:'MerchantApply',
  components:{ MHeader,MUpload },
  data(){
    return {
      title: '',
      tab: 1,          // tabs选中的面板
      form:{
        corporate_name:'',
        email:'',
        surname:'',
        name:'',
        position: '',
        area_code:1,
        phone:'',
        item:'',
        addr: '',
        license_pic: {url: '', pid_id: '' },
        id_card_pic_one: {url: '', pid_id: '' },
        id_card_pic_two: {url: '', pid_id: '' },
      },
      cid: '',
      city:[],
      cityText: '',

      actions: [{ name: '1' }, { name: '86' },],
      telPicker:false,
      itemPicker:false,
      items:[ ],
      showPicker: false,
      columns: [],

      agree:false,
      disabled: false,
      status: -1
    }
  },

  created() {
    this.getCategory()
    this.getCity()

    
    if(this.$route.query.s) {
      this.status = this.$route.query.s
    }

    // 判断状态
    if(this.status == -1) {
      this.title = 'Company Application'
    } else {
      this.title = 'Application'
    }
  },
  mounted(){
    this.tab = this.$route.params.type
    if(this.tab == 1) {
      this.title = "Company Application"
    }else {
      this.title = "Individual Application"
    }
  },
   methods:{
    viewContract() {
      // 查看合同
      this.download('https://m.moobyyoho.com/contract/company.pdf', 'contract.pdf')
    },
    download(url, name) {
        // 发送http请求，将文件链接转换成文件流
        let that = this
        this.fileAjax(url, function(xhr) {
            that.downloadFile(xhr.response, name)
        }, {
            responseType: 'blob'
        })
    },
    fileAjax(url, callback, options) {
        let xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        if (options.responseType) {
            xhr.responseType = options.responseType
        }
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
                callback(xhr)
            }
        }
        xhr.send()
    },
    downloadFile(content, filename) {
        window.URL = window.URL || window.webkitURL
        let a = document.createElement('a')
        let blob = new Blob([content])
        // 通过二进制文件创建url
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        // 销毁创建的url
        window.URL.revokeObjectURL(url)
    },
    // 经营类目
    getCategory() {
      category().then(res => {
        if(res) {
          this.items = res.data
          this.items.forEach(e=> {
            delete e.children
          })
        }
      })
    },

    // 获取城市列表
    getCity() {
      city().then(res => {
        if(res) {
          this.columns = res.data
          this.columns.forEach(e => {
            e.children.forEach(el => {
              el.children.forEach(ele => {
                delete ele.children
              })
            })
          })
        }
      })
    },

    // 选择区号
    handlearea_code(it){
      this.form.area_code = it.name
      this.telPicker = false
    },
    // 上传正面照片
    handleUpload(file){
      this.imgId = 1
      this.imgSrc = file.content
    },
    // 选择经营类目
    handleItem(value){
      // this.form.category = value.title_en
      this.cid = value.id
      this.itemPicker = false
    },

    // 取消 经营类目
    cancelItem() {
      this.form.category = ''
      this.cid           = ''
      this.itemPicker    = false
    },

    // 选择城市 确认
    handleCity(value, ids){
      this.cityText = value.join('-')
      this.city = []
      if(ids.length == 3) {
        this.city.push(this.columns[ids[0]].id)
        this.city.push(this.columns[ids[0]].children[ids[1]].id)
        this.city.push(this.columns[ids[0]].children[ids[1]].children[ids[2]].id)
      }
      this.showPicker = false
    },




    // 提交
    NextBussiness(){

      if(this.form.corporate_name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing Company Name' })
        return false
      }
      if(this.cid == '' && this.form.category == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Please select category' })
        return false
      }
      if(this.form.email == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact email' })
        return false
      }
      if(this.form.surname == '' || this.form.name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact preson' })
        return false
      }
      if(this.form.position == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact tilte' })
        return false
      }
      if(this.form.phone == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact number' })
        return false
      }
      if(this.form.cityText == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Please select area' })
        return false
      }
      if(this.form.addr == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing detail address and zip code' })
        return false
      }
      if(this.form.license_pic.pid_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Upload license' })
        return false
      }
      this.disabled = true
      let params = {
        shops_type     : 1,
        corporate_name : this.form.corporate_name,
        cid            : this.cid,
        custom_category: this.form.item,
        email          : this.form.email,
        surname        : this.form.surname,
        name           : this.form.name,
        area_code      : this.form.area_code,
        phone          : this.form.phone,
        country        : this.city[0],
        province       : this.city[1],
        city           : this.city[2],
        addr           : this.form.addr,
        license_pic    : this.form.license_pic.pid_id
      }

      shop_apply(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: res.msg })
          this.$router.go(-1)
        } else {
          this.$notify({ type: 'warning', duration: 2000, message: res.msg })
        }
      }).finally( () => {
        this.disabled = false
      })
    },


    // 个人商户入驻提交
    NextPreson(){

      if(this.form.corporate_name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing store name' })
        return false
      }
      if(this.cid == '' && this.form.category == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Please select category' })
        return false
      }
      if(this.form.email == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact email' })
        return false
      }
      if(this.form.surname == '' || this.form.name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact preson' })
        return false
      }
      if(this.form.phone == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing contact number' })
        return false
      }
      if(this.form.cityText == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Please select area' })
        return false
      }
      if(this.form.addr == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Missing detail address and zip code' })
        return false
      }
      if(this.form.id_card_pic_one.pid_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Upload ID Front' })
        return false
      }
      if(this.form.id_card_pic_two.pid_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: 'Upload ID Back' })
        return false
      }
      this.disabled = true
      let params = {
        shops_type     : 0,
        corporate_name : this.form.corporate_name,
        cid            : this.cid,
        custom_category: this.form.item,
        email          : this.form.email,
        surname        : this.form.surname,
        name           : this.form.name,
        area_code      : this.form.area_code,
        phone          : this.form.phone,
        country        : this.city[0],
        province       : this.city[1],
        city           : this.city[2],
        addr           : this.form.addr,
        id_card_pic_one: this.form.id_card_pic_one.pid_id,
        id_card_pic_two: this.form.id_card_pic_two.pid_id,
      }

      shop_apply(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: res.msg })
          this.$router.go(-2)
        } else {
          this.$notify({ type: 'warning', duration: 2000, message: res.msg })
        }
      }).finally( () => {
        this.disabled = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './application.less';
</style>